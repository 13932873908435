<template>
  <div>
    <v-tabs v-model="activeTab" class="mb-1" grow>
      <v-tab v-for="tab of tabs"
        :key="tab.index" @click="$router.push({path:tab.url})">{{tab.name}}</v-tab>
    </v-tabs>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: "TourTabs",
    props:['tab', 'tour'],
    data() {
      return {
        activeTab:null,
        tabs:[
          {index:0, name:'Informacion', url:`/backend/admin/tours/${this.tour.id}/edit`},
          {index:1, name:'Tarifas', url:`/backend/admin/tours/${this.tour.id}/rates`},
          {index:2, name:'Imagenes', url:`/backend/admin/tours/${this.tour.id}/images`},
          {index:3, name:'Extras', url:`/backend/admin/tours/${this.tour.id}/extras`},
        ]
      }
    },
    mounted() {
      if (this.tab == 'info') {
        this.activeTab = 0;
      }
      if (this.tab == 'rates') {
        this.activeTab = 1;
      }
      if (this.tab == 'images') {
        this.activeTab = 2;
      }
      if (this.tab == 'extras') {
        this.activeTab = 3;
      }
    }
  }
</script>